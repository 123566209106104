import Link from "next/link";
import Image from "next/image";
import IndexLayout from "components/IndexLayout/IndexLayout";

export default function Custom404() {
  return (
    <>
      <div className="min-h-screen flex flex-col justify-center items-center text-center">
        <Image src="/images/bug.svg" alt="404 icon" width={100} height={100} />
        <h1 className="mt-8 text-4xl">Oh Snap! 404...</h1>
        <p className="mt-4">
          I really don&apos;t know how landed here
          <br />
          but you can always going back to the{" "}
          <Link href="/" className="underline text-stone-400">
            homepage
          </Link>
        </p>
      </div>
    </>
  );
}

Custom404.getLayout = function getLayout(page) {
  return <IndexLayout>{page}</IndexLayout>;
};
